<template>
    <div style="width:100%">
        <app-image></app-image>
        <div class="localDiv">
            <div class="localContent">
                当前位置：网站首页 / <span>关于我们</span>
            </div>
        </div>
        <div class="aboutMenuDiv"  id="showLocation">
            <p>关于我们</p>
            <div class="aboutMenuContent">
                <el-menu
                router
                :default-active="defaultPage"
                mode="horizontal"
                class="el-menu-demo "
                text-color="#404756"
                active-text-color="#3C6AFB"
                >
                    <el-menu-item index="/aboutCompany" >公司介绍</el-menu-item>
                    <el-menu-item index="/aboutNews" >新闻动态</el-menu-item>
                    <el-menu-item index="/aboutQualify" >产品资质</el-menu-item>
                    <el-menu-item index="/aboutMember" >招贤纳士</el-menu-item>
                </el-menu>
            </div>
             
   
        </div>
        <div class="container">
            <div class="container1">
                <router-view />
            </div>
        </div>
    </div>
</template>
<script>
import AppImage from '../../components/common/image.vue'
export default {
    name:"about",
    components:{AppImage},
    data(){
        return{
            defaultPage:"/aboutCompany"
        }
    },
    methods:{
        init(){
             let name=this.$route.name
            this.defaultPage="/"+name
            this.$nextTick(function(){
                document.querySelector('#showLocation').scrollIntoView(true);

            })
        }
    },
    watch: {
        $route() {
            this.init()  //监听路由变化
        }
    },
    created(){
        this.init()
    },
    mounted(){
          
    }
}
</script>
<style>
.localDiv{
    background-color:#F8FAFD;
}
.localContent{
    text-align:right;
    padding:20px 0;
    font-size:15px;
    line-height:15px;
    background-color:#F8FAFD;
    width:70%;
    margin:0 auto;
    
}
.localContent span{
    color:#0665f3
}

.aboutMenuDiv {
    font-size:30px;
    padding-top:60px;
    text-align:center;
    /* border-bottom:1px solid #DBE3EF; */
    box-shadow: 1px 1px 5px #dbe3ef;
}
.aboutMenuContent{
    width:70%;
    margin:0 auto;
    padding-top:30px;
}
.container{
    
    background-image:url(../../static/about_bg1.png);
    background-repeat:no-repeat;
    background-position: bottom;
}
.container .container1{
    width:70%;
    margin:0 auto;
}
</style>
